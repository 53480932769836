<template>
	<div class="h-100">
		<layouts-loading text="Obteniendo información" />
	</div>
</template>

<script>
	import { onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import router from '@/router';
	import store from '@/store';
	import CodeRepository from '@/repositories/CodeRepository';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { redirectBack } = composableRedirect();
			const { catchError } = composableForm();
			const { params: {code} } = useRoute();

			onMounted(() => {
				CodeRepository.check(code)
					.then(onSuccess)
					.catch((e) => {
						catchError(e);
						redirectBack();
					});
			});

			const onSuccess = (response) => {
				store.dispatch('code/setCode', response.data)
					.then(() => router.push({name: 'store.home', params: {slug: response.data.slug}}))
					.catch(catchError);
			}

			return { code };
		}
	}
</script>